import React, { useState } from 'react';
import { Employee } from '@tymbe/legislatives/Employee';
import { Employer } from '@tymbe/legislatives/Employer';
import { WorkShift } from '@tymbe/legislatives/WorkShift';
import { PayPeriod } from '@tymbe/legislatives/PayPeriod';
import { PayPeriodInterval } from '@tymbe/legislatives/PayPeriodInterval';
import { CzechContract } from '@tymbe/legislatives/czechia/CzechContract';
import { CzechContractType } from '@tymbe/legislatives/czechia/CzechContractType';
import { CzechWorkCalculatorFactory } from '@tymbe/legislatives/czechia/CzechWorkCalculatorFactory';
import { CzechDocumentType } from '@tymbe/legislatives/czechia/CzechDocumentType';
import { CzechTestProviders } from '@tymbe/legislatives/czechia/CzechTestProviders';
import { CzechDocument } from '@tymbe/legislatives/czechia/CzechDocument';
import moment from 'moment-timezone';
import { PaySupplementType } from '@tymbe/legislatives/PaySupplementType';
import { CzechSalaryTaxAdjustment } from '@tymbe/legislatives/czechia/CzechSalaryTaxAdjustment';
import { CzechPeriodSalary } from '@tymbe/legislatives/czechia/CzechPeriodSalary';
import { CzechSalaryType } from '@tymbe/legislatives/czechia/CzechSalaryType';
import { PaySupplement } from '@tymbe/legislatives/PaySupplement';
import { CzechWorkCalculator } from '@tymbe/legislatives/czechia/CzechWorkCalculator';
import { WorkBreak } from '@tymbe/legislatives/WorkBreak';
import { timezone } from './timezone';
import { WithKey } from './WithKey';
import { PaySupplementAmountType } from './PaySupplementAmountType';
import { CalculationResult } from './CalculationResult';
import { PaySupplementValueType } from './PaySupplementValueType';
import { CalculatedWorkShift } from './CalculatedWorkShift';
import { WorkShiftStatus } from '@tymbe/legislatives/WorkShiftStatus';
import { WorkShiftType } from '@tymbe/legislatives/WorkShiftType';
import { PaySupplementForCalculator } from './PaySupplementForCalculator';
import { CzechAvailableContract } from '@tymbe/legislatives/czechia/CzechAvailableContract';

export function newMoment(s?: string | number[]): moment.Moment {
  return moment.tz(s, timezone);
}

export default function MonthlySalaryCalculator() {
  const now = newMoment();
  const [payIntervalYear, setPayIntervalYear] = useState(now.year());
  const [payIntervalMonth, setPayIntervalMonth] = useState(now.month() + 1);
  const payInterval: PayPeriodInterval = {
    period: PayPeriod.Monthly,
    startsOn: newMoment([payIntervalYear, payIntervalMonth - 1, 1]).format(),
  };
  const [employeeDateOfBirth, _setEmployeeDateOfBirth] = useState('2000-03-04');
  const employee: Employee = {
    id: 'employee',
    dateOfBirth: newMoment(employeeDateOfBirth).format(),
  };
  const employer: Employer = {
    id: 'employer',
  };
  const [contract, setContract] = useState<CzechContract>({
    type: CzechContractType.DohodaOPracovniCinnosti,
    expectedWeeklyHours: undefined,
    iscoPrefixes: [''],
    id: 'contract',
    templateId: 'irrelevant',
    employeeId: employee.id,
    employerId: employer.id,
    startsOn: now.clone().startOf('year').format(),
    signedOn: now.clone().subtract(1, 'year').endOf('year').format(),
    expiresOn: now.clone().endOf('year').format(),
    endsOn: now.clone().endOf('year').format(),
    fixedValues: {},
  });
  const contractStartsOn = moment.tz(contract.startsOn, timezone);
  const contractExpiresOn = moment.tz(contract.expiresOn, timezone);

  const [workShifts, setWorkShifts] = useState<WithKey<WorkShift>[]>([]);
  const [documents, setDocuments] = useState<WithKey<CzechDocument>[]>([]);
  const [documentNextId, setDocumentNextId] = useState<number>(1);
  const [offerNextId, setOfferNextId] = useState<number>(1);
  const [workNextId, setWorkNextId] = useState<number>(1);
  const [salaryAdvance, setSalaryAdvance] = useState<number>(0);
  const [forceUpdateState, setForceUpdateState] = useState<boolean>(false);
  const [compulsoryWorkBreakStartsAfterMinutes, setCompulsoryWorkBreakStartsAfterMinutes] = useState<number>(0);
  const [allowBypassSalaryLimit, setAllowBypassSalaryLimit] = useState<boolean>(false);
  const [paySupplementRules, setPaySupplementRules] = useState<PaySupplementForCalculator[]>([
    {
      amountType: PaySupplementAmountType.Percentage,
      appliesToEntireShift: true,
      description: '',
      enabled: false,
      fixedHourlyRate: 0,
      percentageIncrease: 100,
      type: PaySupplementType.Holiday,
    },
    {
      amountType: PaySupplementAmountType.Percentage,
      appliesToEntireShift: true,
      description: '',
      enabled: false,
      fixedHourlyRate: 0,
      percentageIncrease: 20,
      type: PaySupplementType.Night,
    },
    {
      amountType: PaySupplementAmountType.Percentage,
      appliesToEntireShift: true,
      description: '',
      enabled: false,
      fixedHourlyRate: 0,
      percentageIncrease: 25,
      type: PaySupplementType.Saturday,
    },
    {
      amountType: PaySupplementAmountType.Percentage,
      appliesToEntireShift: true,
      description: '',
      enabled: false,
      fixedHourlyRate: 0,
      percentageIncrease: 25,
      type: PaySupplementType.Sunday,
    },
  ]);

  const [result, setResult] = useState<CalculationResult>({
    errorMessage: null,
    salary: null,
    workShifts: [],
  });

  function forceUpdate(): void {
    setForceUpdateState(!forceUpdateState);
  }

  function addDocument(): void {
    setDocuments([
      {
        key: documentNextId,
        value: {
          id: String(documentNextId),
          employeeId: employee.id,
          expiresOn: contract.expiresOn,
          startsOn: contract.startsOn,
          type: CzechDocumentType.ProhlaseniPoplatnika,
        },
      },
      ...documents,
    ]);
    setDocumentNextId(documentNextId + 1);
  }

  function removeDocument(documentIndex: number): void {
    documents.splice(documentIndex, 1);
    setDocuments([
      ...documents
    ]);
  }

  function setDocumentType(index: number, type: CzechDocumentType): void {
    const newDocuments = [...documents];
    newDocuments[index].value.type = type;
    setDocuments(newDocuments);
  }

  function setDocumentStartsOn(index: number, dateString: string): void {
    const date = newMoment(dateString);
    if (date.isValid()) {
      const newDocuments = [...documents];
      newDocuments[index].value.startsOn = date.format();
      setDocuments(newDocuments);
    }
  }

  function setDocumentExpiresOn(index: number, dateString: string): void {
    const date = newMoment(dateString);
    if (date.isValid()) {
      const newDocuments = [...documents];
      newDocuments[index].value.expiresOn = date.format();
      setDocuments(newDocuments);
    }
  }

  function addWorkShift(): void {
    const defaultStartTime = contractStartsOn.clone().add(1, 'day').year(payIntervalYear).month(payIntervalMonth - 1).hours(8).minutes(0).seconds(0);
    setWorkShifts([
      ...workShifts,
      {
        key: documentNextId,
        value: {
          contractId: contract.id,
          employeeId: employee.id,
          employerId: employer.id,
          finishTime: defaultStartTime.clone().add(8, 'hours').format(),
          hourlyRate: 150,
          iscoCode: '',
          offerId: String(offerNextId),
          paySupplements: [],
          startTime: defaultStartTime.format(),
          status: WorkShiftStatus.Worked,
          type: WorkShiftType.Regular,
          workBreaks: [],
          workId: String(workNextId),
        },
      }
    ]);
    setDocumentNextId(documentNextId + 1);
    setOfferNextId(offerNextId + 1);
    setWorkNextId(workNextId + 1);
  }

  function removeWorkShift(key: number): void {
    setWorkShifts(workShifts.filter((workShift: WithKey<WorkShift>) => workShift.key !== key));
  }

  function setWorkShiftStartTime(key: number, dateTimeString: string): void {
    if (!newMoment(dateTimeString).isValid()) {
      return;
    }
    const workShift = workShifts.find(workShift => workShift.key === key);
    if (!workShift) {
      return;
    }
    workShift.value.startTime = newMoment(dateTimeString).format();
    setWorkShifts([...workShifts]);
  }

  function setWorkShiftFinishTime(key: number, dateTimeString: string): void {
    if (!newMoment(dateTimeString).isValid()) {
      return;
    }
    const workShift = workShifts.find(workShift => workShift.key === key);
    if (!workShift) {
      return;
    }
    workShift.value.finishTime = newMoment(dateTimeString).format();
    setWorkShifts([...workShifts]);
  }

  function setWorkShiftHourlyRate(key: number, rate: number): void {
    if (isNaN(rate)) {
      rate = 0;
    }
    if (rate < 0) {
      return;
    }
    const workShift = workShifts.find(workShift => workShift.key === key);
    if (!workShift) {
      return;
    }
    workShift.value.hourlyRate = rate;
    setWorkShifts([...workShifts]);
  }

  function setWorkShiftIncludeBreak(key: number, enabled: boolean): void {
    const workShift = workShifts.find(workShift => workShift.key === key);
    if (!workShift) {
      return;
    }
    if (enabled === (workShift.value.workBreaks.length >= 1)) {
      return;
    }
    if (enabled) {
      workShift.value.workBreaks = [{
        startsAt: newMoment(workShift.value.startTime).add(1, 'hour').format(),
        durationInMinutes: 30,
        endsAt: newMoment(workShift.value.startTime).add(1.5, 'hours').format(),
      }];
    } else {
      workShift.value.workBreaks = [];
    }
    setWorkShifts([...workShifts]);
  }

  function setWorkShiftBreakStart(key: number, dateTimeString: string): void {
    if (!newMoment(dateTimeString).isValid()) {
      return;
    }
    const workShift = workShifts.find(workShift => workShift.key === key);
    if (!workShift) {
      return;
    }
    const start = newMoment(dateTimeString);
    const end = start.clone().add(workShift.value.workBreaks[0].durationInMinutes, 'minutes');
    workShift.value.workBreaks[0].startsAt = start.format();
    workShift.value.workBreaks[0].endsAt = end.format();
    setWorkShifts([...workShifts]);
  }

  function setWorkShiftBreakDuration(key: number, duration: number): void {
    duration = isNaN(duration) ? 0 : duration;
    if (duration < 0) {
      return;
    }
    const workShift = workShifts.find(workShift => workShift.key === key);
    if (!workShift) {
      return;
    }
    workShift.value.workBreaks[0].durationInMinutes = duration;
    workShift.value.workBreaks[0].endsAt = newMoment(workShift.value.workBreaks[0].startsAt).add(duration, 'minutes').format();
    setWorkShifts([...workShifts]);
  }

  function setEmployeeDateOfBirth(dateString: string): void {
    if (newMoment(dateString).isValid()) {
      employee.dateOfBirth = newMoment(dateString).format();
      _setEmployeeDateOfBirth(dateString);
    }
  }

  function setContractDateProperty(propertyName: 'signedOn' | 'startsOn' | 'expiresOn', dateString: string): void {
    const date = newMoment(dateString);
    if (date.isValid()) {
      contract[propertyName] = date.format();
      if (propertyName === "expiresOn") {
        contract.endsOn = date.format();
      }
      forceUpdate();
    }
  }

  function setContractType(contractType: CzechContractType): void {
    contract.type = contractType;
    setContract(contract);
    forceUpdate();
  }

  function setPaySupplementRuleProperty(
    type: PaySupplementType,
    property: keyof PaySupplementForCalculator,
    value: PaySupplementValueType,
  ): void {
    const paySupplement = paySupplementRules.find((rule: PaySupplementForCalculator) => rule.type === type);
    if (paySupplement) {
      if (property === 'amountType') {
        const propertyName = value === PaySupplementAmountType.Percentage ? 'percentageIncrease' : 'fixedHourlyRate';
        paySupplement[propertyName] = 0;
      }
      (paySupplement[property] as PaySupplementValueType) = value;
    }
    setPaySupplementRules(paySupplementRules);
    forceUpdate();
  }

  function onChangeCompulsoryWorkBreakStartsAfterMinutes(value: string): void {
    const minutes = Math.max(Math.trunc(Number(value)) ?? 0, 0);
    if (minutes >= 0) {
      setCompulsoryWorkBreakStartsAfterMinutes(minutes);
      forceUpdate();
    }
  }

  function getIntervalForWorkShift(workShift: WorkShift): PayPeriodInterval {
    const workShiftStartTime = newMoment(workShift.startTime);
    const startDate = newMoment([workShiftStartTime.year(), workShiftStartTime.month(), 1]);

    const payPeriodInterval: PayPeriodInterval = {
      period: PayPeriod.Monthly,
      startsOn: startDate.format(),
    };

    return payPeriodInterval;
  }

  async function recalculate(): Promise<void> {
    payInterval.startsOn = newMoment([payIntervalYear, payIntervalMonth - 1, 1]).format();
    const providers = new CzechTestProviders();
    const calculator = new CzechWorkCalculatorFactory().create(
      providers.employee,
      providers.employer,
      providers.salaryAdvance,
      providers.workShift,
      providers.document,
      providers.contract,
    );
    const availableContract: CzechAvailableContract = {
      computeExpiresOn(_startsOn) {
        return contract.endsOn;
      },
      iscoPrefixes: [''],
      fixedContractId: contract.id,
      fixedValues: {},
      startsOn: contract.startsOn,
      templateId: contract.templateId,
      type: contract.type,
    };

    workShifts.forEach(workShift => {
      if (workShift.value.workBreaks.length > 1) {
        workShift.value.workBreaks = [workShift.value.workBreaks[0]];
      }
    });

    providers.addEmployee(employee);
    providers.addEmployer(employer);
    providers.addContract(contract);
    providers.addDocuments(contract.id, documents.map(document => document.value));

    if (salaryAdvance) {
      providers.salaryAdvance.add({
        employeeId: contract.employeeId,
        employerId: contract.employerId,
        paidOn: newMoment([payIntervalYear, payIntervalMonth - 1, 7]).format(),
        amount: salaryAdvance,
        type: contract.type === CzechContractType.DohodaOProvedeniPrace
          ? CzechSalaryType.DPP
          : CzechSalaryType.DPC_HPP,
      });
    }

    let errorMessage: string | null = null;

    const calculatedWorkShifts: CalculatedWorkShift[] = [];
    try {
      const paySupplementRulesForCalculator = getPaySupplementRulesForCalculator(paySupplementRules);

      for (const record of workShifts) {
        const workShift: WorkShift = {
          ...record.value,
          workBreaks: record.value.workBreaks.map((workBreak: WorkBreak) => ({...workBreak}))
        }

        if (getIntervalForWorkShift(workShift).startsOn !== payInterval.startsOn) {
           continue;
        }

        CzechWorkCalculator.addPaySupplementsToWorkShift(workShift, paySupplementRulesForCalculator);
        CzechWorkCalculator.addRequiredBreakToWorkShift(workShift, employee, undefined, compulsoryWorkBreakStartsAfterMinutes);
        const signUp = await calculator.canEmployeeSignUpForWorkShift(employee.id, workShift, [availableContract], allowBypassSalaryLimit, allowBypassSalaryLimit, true);
        providers.workShift.add(workShift);
        const salary = CzechWorkCalculator.calculateForWorkShift(workShift);
        calculatedWorkShifts.push({
          workShift,
          signUp,
          salary,
        });
      }

      const salaries: CzechPeriodSalary[] = await calculator.calculateSalaries(
        employee.id,
        employer.id,
        payInterval,
        allowBypassSalaryLimit,
        allowBypassSalaryLimit,
      );
      setResult({
        errorMessage: null,
        salary: salaries[0],
        workShifts: calculatedWorkShifts,
      });
    } catch (error) {
      if (error instanceof Error) {
        errorMessage = error.message;
      } else {
        errorMessage = JSON.stringify(error);
      }

      setResult({
        errorMessage: errorMessage,
        workShifts: calculatedWorkShifts,
        salary: null,
      });
    }
  }

  function getExportString(): string {
    return JSON.stringify({
      payIntervalYear,
      payIntervalMonth,
      employeeDateOfBirth,
      payInterval,
      contract,
      workShifts,
      documents,
      documentNextId,
      salaryAdvance,
      paySupplementRules,
    });
  }

  async function copyExport(): Promise<void> {
    const exportTextarea = document.getElementById('exportTextarea') as HTMLInputElement;
    if (!exportTextarea) {
      return;
    }
    exportTextarea.focus();
    exportTextarea.setSelectionRange(0, 9999);
    await navigator.clipboard.writeText(getExportString());
  }

  async function pasteImport(): Promise<void> {
    const importTextarea = document.getElementById('importTextarea') as HTMLInputElement;
    if (!importTextarea) {
      return;
    }
    try {
      importTextarea.value = await navigator.clipboard.readText();
    } catch (err) {
      console.error(err);
    }
  }

  function submitImport(): void {
    const importTextarea = document.getElementById('importTextarea') as HTMLInputElement;
    if (!importTextarea) {
      return;
    }
    if (!importTextarea.value) {
      alert('Neplatný datový řetězec');
      return;
    }
    try {
      importString(importTextarea.value);
      importTextarea.value = '';
    } catch {
      alert('Neplatný datový řetězec');
    }
  }

  function importString(str: string): void {
    const importData = JSON.parse(str);
    setPayIntervalYear(importData.payIntervalYear);
    setPayIntervalMonth(importData.payIntervalMonth);
    _setEmployeeDateOfBirth(importData.employeeDateOfBirth);
    payInterval.period = importData.payInterval.period;
    payInterval.startsOn = newMoment(importData.payInterval.startsOn).format();
    employee.dateOfBirth = newMoment(importData.employeeDateOfBirth).format();
    setCompulsoryWorkBreakStartsAfterMinutes(importData.compulsoryWorkBreakStartsAfterMinutes);
    setAllowBypassSalaryLimit(importData.contract.allowBypassSalaryLimit);
    contract.expiresOn = newMoment(importData.contract.expiresOn).format();
    contract.signedOn = newMoment(importData.contract.signedOn).format();
    contract.startsOn = newMoment(importData.contract.startsOn).format();
    contract.type = importData.contract.type;
    setPaySupplementRules(importData.paySupplementRules);
    setWorkShifts(importData.workShifts.map((workShift: any) => {
      workShift.value.startTime = newMoment(workShift.value.startTime);
      workShift.value.finishTime = newMoment(workShift.value.finishTime);
      workShift.value.workBreaks = workShift.value.workBreaks.map((workBreak: any) => {
        workBreak.startsAt = newMoment(workBreak.startsAt);
        workBreak.endsAt = newMoment(workBreak.endsAt);
        return workBreak;
      })
      return workShift;
    }));
    setDocuments(importData.documents.map((document: any) => {
      document.value.startsOn = newMoment(document.value.startsOn);
      document.value.expiresOn = newMoment(document.value.expiresOn);
      return document;
    }));
    setDocumentNextId(importData.documentNextId);
    setSalaryAdvance(importData.salaryAdvance);
    forceUpdate();
  }

  function paySupplementTypeToString(type: PaySupplementType): string {
    return {
      [PaySupplementType.Holiday]: "Příplatek za práci o svátcích",
      [PaySupplementType.Night]: "Příplatek za práci v noci",
      [PaySupplementType.Saturday]: "Příplatek za práci v sobotu",
      [PaySupplementType.Sunday]: "Příplatek za práci v neděli",
    }[type] ?? type.toString();
  }

  function documentTypeToString(documentType: CzechDocumentType): string {
    return {
      [CzechDocumentType.PotvrzeniOStudiu]: 'Potvrzení o studiu',
      [CzechDocumentType.ProhlaseniPoplatnika]: 'Prohlášení poplatníka',
      [CzechDocumentType.PotvrzeniOEvidenciNaUraduPrace]: 'Potvrzení o evidenci na úřadu práce',
      [CzechDocumentType.PotvrzeniJinehoZamestnavateleOOdvoduZdravotnihoPojisteni]: 'Potvrzení jiného zaměstnavatele o odvodu zdravotního pojištění',
      [CzechDocumentType.VymerMaterskeRodicovskeDovolene]: 'Výměr mateřské rodičovské dovolené',
      [CzechDocumentType.VymerStarobnihoDuchodu]: 'Výměr starobního důchodu',
      [CzechDocumentType.VymerInvalidnihoDuchodu12Stupne]: 'Výměr invalidního důchodu 1., 2. stupně',
      [CzechDocumentType.VymerInvalidnihoDuchodu3Stupne]: 'Výměr invalidního důchodu 3. stupně',
      [CzechDocumentType.PrukazZTPP]: 'Průkaz ZTP/P',
    }[documentType.toString()] ?? documentType.toString();
  }

  function displayMinutes(minutes: number): string {
    const displayedHours = Math.floor(minutes / 60);
    const displayedMinutes = minutes % 60;
    return `${displayedHours ? displayedHours + 'h': ''} ${displayedMinutes}m`;
  }

  function getPaySupplementRulesForCalculator(rules: PaySupplementForCalculator[]): PaySupplement[] {
    return rules
      .filter((rule: PaySupplementForCalculator) => rule.enabled)
      .map((rule: PaySupplementForCalculator) => {
        const { enabled: _eenabled, amountType: _amountType, ...rest } = rule;
        return rest;
      });
  }

  return (
    <div className="App">
      <div className="navbar navbar-expand-lg bg-body-tertiary">
        <div className="container-fluid">
          <a className="navbar-brand" href="#_">
            Tymbe - Kalkulačka měsíční mzdy
          </a>
        </div>
      </div>

      <div className="container mt-3">
        {result.errorMessage &&
          <div className="alert alert-danger mt-3">
            {result.errorMessage}
          </div>
        }
        <div className="row">
          <div className="col-6">
            <div className="card">
              <div className="card-header">
                <b>Nastavení výpočtu</b>
              </div>
                <div className="card-body">
                  <div className="mb-2">
                    <label className="form-label">
                      Období výpočtu
                    </label>
                    <div className="row">
                      <div className="col-6">
                        <div className="input-group col">
                          <span className="input-group-text">Rok</span>
                          <select
                            className="form-select"
                            onChange={ev => setPayIntervalYear(parseInt((ev.target as HTMLSelectElement).value))}
                            value={payIntervalYear}
                          >
                            {[...Array(10).keys()].map(x => 2020 + x).map(year => <option key={year} value={year}>{year}</option>)}
                          </select>
                        </div>
                      </div>
                      <div className="col-6">
                        <div className="input-group">
                          <span className="input-group-text">Měsíc</span>
                          <select
                            className="form-select"
                            onChange={ev => setPayIntervalMonth(parseInt((ev.target as HTMLSelectElement).value))}
                            value={payIntervalMonth}
                            >
                              {[...Array(12).keys()].map(x => 1 + x).map(month => <option key={month} value={month}>{month}</option>)}
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>


                  </div>
                  <div className="mb-2">
                    <label className="form-label">Výše již vyplacených záloh</label>
                    <div className="input-group">
                      <input
                        type="number"
                        className="form-control"
                        min={0}
                        onChange={ev => setSalaryAdvance(parseInt(ev.target.value))}
                        value={salaryAdvance}
                        placeholder="0"
                      />
                      <span className="input-group-text">Kč</span>
                    </div>

                  </div>
              </div>
            </div>
            <div className="card mt-3">
              <div className="card-header">
                <b>Smlouva</b>
              </div>
              <div className="card-body">
                <div className="mb-2">
                  <label className="form-label">Podepsáno dne</label>
                  <input
                    type="date"
                    className="form-control"
                    value={newMoment(contract.signedOn).format("YYYY-MM-DD")}
                    onChange={ev => setContractDateProperty("signedOn", ev.target.value)}
                  />
                </div>
                <div className="row mb-2">
                  <div className="col-6">
                    <label className="form-label">Platnost od</label>
                    <input
                      type="date"
                      className={`form-control ${contractStartsOn.isAfter(contractExpiresOn) ? 'is-invalid' : ''}`}
                      value={contractStartsOn.format("YYYY-MM-DD")}
                      onChange={ev => setContractDateProperty("startsOn", ev.target.value)}
                    />
                    <div className="invalid-feedback">
                      Začátek platnosti by měl být dříve než konec !
                    </div>
                  </div>
                  <div className="col-6">
                    <label className="form-label">Platnost do</label>
                    <input
                      type="date"
                      className={`form-control ${contractStartsOn.isAfter(contractExpiresOn) ? 'is-invalid' : ''}`}
                      value={contractExpiresOn.format("YYYY-MM-DD")}
                      onChange={ev => setContractDateProperty("expiresOn", ev.target.value)}
                    />
                  </div>

                </div>
                <div className="mb-2">
                  <label className="form-label">Typ smlouvy</label>
                  <div className="form-check">
                    <input
                      type="radio"
                      name="czechContractType"
                      id="czechContractTypeDPP"
                      className="form-check-input"
                      checked={contract.type === CzechContractType.DohodaOProvedeniPrace}
                      onChange={ev => {
                        if ((ev.target as HTMLInputElement).checked) {
                          setContractType(CzechContractType.DohodaOProvedeniPrace);
                        }
                      }}
                    />
                    <label className="form-check-label" htmlFor="czechContractTypeDPP">
                      Dohoda o provedení práce
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      type="radio"
                      name="czechContractType"
                      id="czechContractTypeDPC"
                      className="form-check-input"
                      checked={contract.type === CzechContractType.DohodaOPracovniCinnosti}
                      onChange={ev => {
                        if ((ev.target as HTMLInputElement).checked) {
                          setContractType(CzechContractType.DohodaOPracovniCinnosti);
                        }
                      }}
                    />
                    <label className="form-check-label" htmlFor="czechContractTypeDPC">
                      Dohoda o pracovní činnosti
                    </label>
                  </div>
                  <div className="form-check">
                    <input
                      type="radio"
                      name="czechContractType"
                      id="czechContractTypeHPP"
                      className="form-check-input"
                      checked={contract.type === CzechContractType.HlavniPracovniPomer}
                      onChange={ev => {
                        if ((ev.target as HTMLInputElement).checked) {
                          setContractType(CzechContractType.HlavniPracovniPomer);
                        }
                      }}
                    />
                    <label className="form-check-label" htmlFor="czechContractTypeHPP">
                      Hlavní pracovní poměr
                    </label>
                  </div>
                </div>

                <div className="mb-2">
                  <label className="form-label">Umístění přestávky při automatickém doplnění</label>
                  <input
                    type="number"
                    className="form-control"
                    min={0}
                    step={1}
                    placeholder="automaticky"
                    value={compulsoryWorkBreakStartsAfterMinutes === 0 ? '' : compulsoryWorkBreakStartsAfterMinutes}
                    onChange={ev => onChangeCompulsoryWorkBreakStartsAfterMinutes(ev.target.value)}
                  />
                </div>

                <div className="form-check">
                  <input
                    type="checkbox"
                    id="czechContractAllowBypassSalaryLimit"
                    className="form-check-input"
                    checked={allowBypassSalaryLimit}
                    onChange={ev => {
                      setAllowBypassSalaryLimit(ev.target.checked);
                      forceUpdate();
                    }}
                  />
                  <label className="form-check-label" htmlFor="czechContractAllowBypassSalaryLimit">
                    Povolit překročení měsíčního limitu mzdy
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="col-6">
            <div className="card">
              <div className="card-header">
                <b>Zaměstnanec</b>
              </div>
              <div className="card-body">
                <div className="mb-2">
                    <label className="form-label">
                      Datum narození
                    </label>
                    <input
                      type="date"
                      className="form-control"
                      value={employeeDateOfBirth}
                      onChange={ev => setEmployeeDateOfBirth(ev.target.value)}
                    />
                </div>
              </div>
            </div>
            <div className="card mt-3">
              <div className="card-header">
                <b>Příplatky</b>
              </div>

              <div className="card-body">
                {paySupplementRules.map((rule: PaySupplementForCalculator, index: number) => {
                    const enabledId = `${rule.type}-enabled`;
                    const appliesToEntireShiftId = `${rule.type}-appliesToEntireShift`;

                    return (
                      <div key={rule.type}>
                        <div className="form-check">
                          <input
                            checked={rule.enabled}
                            className="form-check-input"
                            id={enabledId}
                            onChange={ev => setPaySupplementRuleProperty(rule.type, "enabled", ev.target.checked)}
                            type="checkbox"
                          />
                          <label className="form-check-label" htmlFor={enabledId}>
                            {paySupplementTypeToString(rule.type)}
                          </label>
                        </div>

                        <div className="mx-4">
                          {(Object.keys(PaySupplementAmountType) as PaySupplementAmountType[]).map((amountType: PaySupplementAmountType) => {
                            const amountTypeId = `${rule.type}-${amountType}`;
                            return (
                              <div className="form-check" key={amountTypeId}>
                                <input
                                  checked={rule.amountType === amountType}
                                  className="form-check-input"
                                  disabled={!rule.enabled}
                                  id={amountTypeId}
                                  name={amountTypeId}
                                  onChange={ev => ev.target.checked && setPaySupplementRuleProperty(rule.type, "amountType", amountType)}
                                  type="radio"
                                />
                                <label className="form-check-label" htmlFor={amountTypeId}>
                                  {amountType === PaySupplementAmountType.Fixed && "Fixní příplatek"}
                                  {amountType === PaySupplementAmountType.Percentage && "Procentuální příplatek"}
                                </label>
                              </div>
                            );
                          })}

                          <div className="input-group">
                            <input
                              className="form-control"
                              disabled={!rule.enabled}
                              max={rule.amountType === PaySupplementAmountType.Percentage ? 100 : undefined}
                              min={0}
                              onChange={ev => setPaySupplementRuleProperty(rule.type, rule.amountType === PaySupplementAmountType.Percentage ? "percentageIncrease" : "fixedHourlyRate", parseInt(ev.target.value))}
                              type="number"
                              value={rule.amountType === PaySupplementAmountType.Percentage ? rule.percentageIncrease : rule.fixedHourlyRate}
                            />
                            <span className="input-group-text">
                              {rule.amountType === PaySupplementAmountType.Fixed && 'Kč/h'}
                              {rule.amountType === PaySupplementAmountType.Percentage && '%'}
                            </span>
                          </div>

                          <div className="form-check">
                            <input
                              checked={rule.appliesToEntireShift}
                              className="form-check-input"
                              disabled={!rule.enabled}
                              id={appliesToEntireShiftId}
                              onChange={ev => setPaySupplementRuleProperty(rule.type, "appliesToEntireShift", ev.target.checked)}
                              type="checkbox"
                            />
                            <label className="form-check-label" htmlFor={appliesToEntireShiftId}>
                              Příplatek se vztahuje na celou směnu.
                            </label>
                          </div>

                          {index !== paySupplementRules.length - 1 && <hr />}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>

        <div className="card mt-3">
          <div className="card-header d-flex justify-content-between align-items-center">
            <b>Dokumenty</b>
            <button onClick={addDocument} className="btn btn-success btn-sm align-right">
              Přidat dokument
            </button>
          </div>
          <div className="card-body">
            {documents.length === 0 && (
              <p className="text-center text-body-secondary">
                Nejsou přidány žádné dokumenty
              </p>
            )}
            {documents.length > 0 && (
              <table className="table">
                <thead>
                  <tr>
                    <th>Typ</th>
                    <th>Platí od</th>
                    <th>Platí do</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                {documents.map((document, documentIndex) =>
                  <tr key={document.key}>
                    <td>
                      <select
                        value={document.value.type}
                        className="form-select"
                        onChange={ev => setDocumentType(documentIndex, (ev.target as HTMLSelectElement).value as CzechDocumentType)}
                      >
                        {Object.values(CzechDocumentType).map(type => <option key={type} value={type}>{documentTypeToString(type)}</option>)}
                      </select>
                    </td>
                    <td>
                      <input
                        type="date"
                        className={`form-control ${newMoment(document.value.startsOn).isAfter(document.value.expiresOn) ? 'is-invalid' : ''}`}
                        value={newMoment(document.value.startsOn).format("YYYY-MM-DD")}
                        onChange={ev => setDocumentStartsOn(documentIndex, (ev.target as HTMLInputElement).value)}
                      />
                      <div className="invalid-feedback">
                        Platnost končí dříve než začíná !
                      </div>
                    </td>
                    <td>
                      <input
                        type="date"
                        className={`form-control ${newMoment(document.value.startsOn).isAfter(document.value.expiresOn) ? 'is-invalid' : ''}`}
                        value={newMoment(document.value.expiresOn).format("YYYY-MM-DD")}
                        onChange={ev => setDocumentExpiresOn(documentIndex, (ev.target as HTMLInputElement).value)}
                      />
                    </td>
                    <td>
                      <button
                        className="btn-close mt-2 color-red"
                        onClick={() => removeDocument(documentIndex)}
                      ></button>
                    </td>
                  </tr>
                )}
                </tbody>
              </table>
            )}
          </div>
        </div>

        <div className="card mt-3">
          <div className="card-header d-flex justify-content-between align-items-center">
            <b>Směny</b>
            <button onClick={addWorkShift} className="btn btn-success btn-sm">Přidat směnu</button>
          </div>
          <div className="card-body">
            {workShifts.length === 0 && (
              <p className="text-center text-body-secondary">
                Nejsou přidány žádné směny
              </p>
            )}
            {workShifts.length > 0 && (
              <>
                <table className="table">
                  <thead>
                    <tr>
                      <th></th>
                      <th>Začátek směny</th>
                      <th>Konec směny</th>
                      <th>Hodinová mzda</th>
                      <th>Přestávka</th>
                      <th>Začátek přestávky</th>
                      <th>Délka přestávky</th>
                      <th></th>
                    </tr>
                  </thead>
                  <tbody>
                    {workShifts.map(workShift => {
                      const workShiftPayInterval = getIntervalForWorkShift(workShift.value);
                      const workShiftStartTime = moment.tz(workShift.value.startTime, timezone);
                      const workShiftFinishTime = moment.tz(workShift.value.finishTime, timezone);
                      return (
                        <tr key={workShift.key} className={workShiftPayInterval.startsOn !== payInterval.startsOn ? "opacity-50" : ""}>

                          <td className={workShiftPayInterval.startsOn === payInterval.startsOn ? "text-success" : "text-danger"}>
                            {workShiftPayInterval.startsOn === payInterval.startsOn ? "✓" : "✕"}
                          </td>
                          <td>
                            <input
                              type="datetime-local"
                              className={`form-control ${(workShiftStartTime.isAfter(workShiftFinishTime) || workShiftStartTime.isBefore(contractStartsOn) || workShiftStartTime.isAfter(contractExpiresOn)) ? 'is-invalid' : ''}`}
                              value={workShiftStartTime.format("YYYY-MM-DDTHH:mm")}
                              onChange={ev => setWorkShiftStartTime(workShift.key, ev.target.value)}
                            />
                            <div className="invalid-feedback">
                              <div>{workShiftStartTime.isAfter(workShift.value.finishTime) && 'Směna končí dříve než začíná !'}</div>
                              <div>{workShiftStartTime.isBefore(contractStartsOn) && 'Před začátkem smlouvy !'}</div>
                              <div>{workShiftStartTime.isAfter(contractExpiresOn) && 'Po konci smlouvy !'}</div>
                            </div>
                          </td>
                          <td>
                            <input
                              type="datetime-local"
                              className={`form-control ${(workShiftStartTime.isAfter(workShiftFinishTime) || workShiftFinishTime.isBefore(contractStartsOn) || workShiftFinishTime.isAfter(contractExpiresOn)) ? 'is-invalid' : ''}`}
                              value={workShiftFinishTime.format("YYYY-MM-DDTHH:mm")}
                              onChange={ev => setWorkShiftFinishTime(workShift.key, ev.target.value)}
                            />
                            <div className="invalid-feedback">
                              <div>{workShiftStartTime.isAfter(workShiftFinishTime) && 'Směna končí dříve než začíná !'}</div>
                              <div>{workShiftFinishTime.isBefore(contractStartsOn) && 'Před začátkem smlouvy !'}</div>
                              <div>{workShiftFinishTime.isAfter(contractExpiresOn) && 'Po konci smlouvy !'}</div>
                            </div>
                          </td>
                          <td>
                            <input
                              type="number"
                              className={`form-control ${workShift.value.hourlyRate === 0 ? 'is-invalid' : ''}`}
                              value={workShift.value.hourlyRate === 0 ? '' : workShift.value.hourlyRate}
                              min={1}
                              onChange={ev => setWorkShiftHourlyRate(workShift.key, parseInt(ev.target.value))}
                              placeholder='0'
                            />
                            <div className="invalid-feedback">
                              Nulová mzda !
                            </div>
                          </td>
                          <td className="text-center">
                            <div className="justify-content-center">
                              <input
                                type="checkbox"
                                className="form-check-input"
                                checked={workShift.value.workBreaks.length > 0}
                                onChange={ev => setWorkShiftIncludeBreak(workShift.key, ev.target.checked)}
                              />
                            </div>

                          </td>
                          {(workShift.value.workBreaks.length > 0) && (
                            <>
                              <td>
                                <input
                                  type="datetime-local"
                                  className="form-control"
                                  value={newMoment(workShift.value.workBreaks[0].startsAt).format("YYYY-MM-DDTHH:mm")}
                                  onChange={ev => setWorkShiftBreakStart(workShift.key, ev.target.value)}
                                />
                              </td>
                              <td>
                                <div className="input-group">
                                  <input
                                    type="number"
                                    className="form-control"
                                    value={workShift.value.workBreaks[0].durationInMinutes}
                                    min={0}
                                    onChange={ev => setWorkShiftBreakDuration(workShift.key, parseInt(ev.target.value))}
                                  />
                                  <span className="input-group-text">
                                  min
                                </span>
                                </div>
                              </td>
                            </>
                          )}
                          {(workShift.value.workBreaks.length === 0) && (
                            <>
                              <td>
                                -
                              </td>
                              <td>
                                -
                              </td>
                            </>
                          )}

                          <td>
                            <button
                              className="btn-close mt-2"
                              onClick={() => removeWorkShift(workShift.key)}
                            ></button>
                          </td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
                <div className="text-muted">
                  <span className="text-success">✓</span> - Zahrnuto do výpočtu
                </div>
                <div className="text-muted">
                  <span className="text-danger">✕</span> - Mimo vybrané období pro výpočet
                </div>
              </>
            )}
          </div>
        </div>

        {result.errorMessage &&
          <div className="alert alert-danger mt-3">
            {result.errorMessage}
          </div>
        }

        <div className="mt-3 mb-3">
          <button onClick={recalculate} className="btn btn-primary me-2">Vypočítat</button>
          <button className="btn btn-secondary  me-2" data-bs-toggle="modal" data-bs-target="#exportModal">Export</button>
          <button className="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#importModal">Import</button>
        </div>

        <div className="modal fade" id="exportModal">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Export</h5>
                <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <textarea className="form-control" value={getExportString()} id="exportTextarea" readOnly={true} />
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Zavřít</button>
                <button type="button" className="btn btn-primary" onClick={copyExport}>Kopírovat</button>
              </div>
            </div>
          </div>
        </div>

        <div className="modal fade" id="importModal">
          <div className="modal-dialog">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title">Import</h5>
                <button className="btn-close" type="button" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                <span className="form-label">Vložte datový řetězec:</span>
                <textarea id="importTextarea" className="form-control"></textarea>
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Zavřít</button>
                <button type="button" className="btn btn-secondary" onClick={pasteImport}>Vložit</button>
                <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={submitImport}>Import</button>
              </div>
            </div>
          </div>
        </div>


        {!result.errorMessage && (result.salary || result.workShifts.length > 0) && (
          <>
            <h3 className="mt-3 mb-3">Výsledek</h3>
            {result.salary &&
              <div className="card mb-3">
                <h5 className="card-header">Rozpis mzdy</h5>
                <div className="card-body">
                  <table className="table">
                    <tbody>
                      <tr>
                        <td>
                          <b>
                            Hrubá mzda
                          </b>
                        </td>
                        <td className="text-end">
                          <b>
                            {result.salary.totalGrossSalary} Kč
                          </b>
                        </td>
                      </tr>
                      {result.salary.taxes.map(tax => (
                        <React.Fragment key={tax.name}>
                          <tr>
                            <td>
                              {tax.name} <small className="text-muted">({tax.description})</small>
                            </td>
                            <td className="text-end">
                              {tax.finalAmount} Kč
                            </td>
                          </tr>
                          <tr>
                            <td className="ps-5">
                              Základ daně
                            </td>
                            <td className="text-end text-secondary fw-light">
                              {tax.baseAmount} Kč
                            </td>
                          </tr>
                          {result.salary?.taxAdjustments.filter((adjustment: CzechSalaryTaxAdjustment) => adjustment.appliedToTaxType === tax.type).map(adjustment => (
                            <tr key={adjustment.name}>
                              <td className="ps-5">
                                {adjustment.name}
                              </td>
                              <td className="text-end text-secondary fw-light">
                                {adjustment.totalAmount} Kč
                              </td>
                            </tr>
                          ))}
                        </React.Fragment>
                      ))}
                      <tr>
                        <td>
                          <b>
                            Čistá mzda
                          </b>
                        </td>
                        <td className="text-end">
                          <b>
                            {result.salary.totalNetSalary} Kč
                          </b>
                        </td>
                      </tr>
                      {result.salary.unpaidNetSalary !== result.salary.unpaidNetSalaryAdvance &&
                        <tr>
                          <td>
                            Předběžná záloha na sociální a zdravotní pojištění
                          </td>
                          <td className="text-end">
                            {result.salary.unpaidNetSalary - result.salary.unpaidNetSalaryAdvance >= 0 ? "-" : ""}
                            {result.salary.unpaidNetSalary - result.salary.unpaidNetSalaryAdvance} Kč
                          </td>
                        </tr>
                      }
                      {result.salary.advances.map(advance => (
                        <tr key={advance.paidOn.toString()}>
                          <td>
                            Vyplacená záloha <small className="text-muted"> | dne {newMoment(advance.paidOn).format('DD. MM. YYYY')}</small>
                          </td>
                          <td className="text-end">
                            {advance.amount * -1} Kč
                          </td>
                        </tr>
                      ))}
                      <tr>
                        <td>
                          <b>
                            Zbývá k vyplacení
                          </b>
                        </td>
                        <td className="text-end">
                          <b>{result.salary.unpaidNetSalaryAdvance} Kč</b>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            }
            {result.workShifts.length > 0 &&
              <div className="card mb-3">
                <h5 className="card-header">Spočítané směny ({result.workShifts.length})</h5>
                <div className="card-body">
                  <table className="table table-striped">
                    <thead>
                      <tr>
                        <th>Začátek směny</th>
                        <th>Konec směny</th>
                        <th>Hodinová mzda</th>
                        <th>Přestávky</th>
                        <th>Odpracovaný čas</th>
                        <th>Celkový čas</th>
                        <th>Příplatky</th>
                        <th>Celková mzda</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {result.workShifts.map((calculatedWorkShift, index) =>
                        <tr key={calculatedWorkShift.workShift.startTime.toString() + index}>
                          <td>{newMoment(calculatedWorkShift.workShift.startTime).format('HH:mm (DD. MM. YYYY)')}</td>
                          <td>{newMoment(calculatedWorkShift.workShift.finishTime).format('HH:mm (DD. MM. YYYY)')}</td>
                          <td>{calculatedWorkShift.workShift.hourlyRate} Kč</td>
                          <td>
                            {calculatedWorkShift.workShift.workBreaks.map((workBreak, index) => (
                              <div key={workBreak.startsAt.toString() + index}>
                                {newMoment(workBreak.startsAt).format('HH:mm')} - {newMoment(workBreak.endsAt).format('HH:mm')} ({workBreak.durationInMinutes} minut)
                              </div>
                            ))}
                          </td>
                          <td>{displayMinutes(calculatedWorkShift.salary.workTimeTotalNetMinutes)}</td>
                          <td>{displayMinutes(calculatedWorkShift.salary.workTimeTotalMinutes)}</td>
                          <td>
                            {calculatedWorkShift.salary.paySupplements.length === 0 && '-'}
                            {calculatedWorkShift.salary.paySupplements.map((supplement, index) => (
                              <div key={supplement.paySupplement.type + index}>

                                {paySupplementTypeToString(supplement.paySupplement.type)}:&nbsp;
                                <small>
                                  {supplement.totalGrossSalary} Kč
                                </small>
                              </div>
                            ))}
                          </td>
                          <td>{calculatedWorkShift.salary.totalGrossSalary} Kč</td>
                          <td>
                            {!calculatedWorkShift.signUp.canSignUp && (
                              <span title={calculatedWorkShift.signUp.issues.map((issue) => issue.message).join(';')}>⚠️</span>
                            )}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            }
          </>
        )}
      </div>
    </div>
  )
}
